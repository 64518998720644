<template>
<div class="com-goods-comment">
    <div @click="showComment">
        <slot></slot>
    </div>
    <a-modal title="商品评论" v-model:visible="show" :footer="null" @cancel="show = false" width="1000px">
        <a-table size="small" :pagination="false" :data-source="gcStatus.list" row-key="id" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'评论内容',dataIndex:'content'},
            {title:'评论图片',dataIndex:'image',slots:{customRender:'image'}},
            {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" >
            <template #image="{record}">
                <a-space>
                    <a-image width="50px"
                             height="50px"
                             v-for="(item,index) in record.src"
                             :src="item"
                             :key="index">
                    </a-image>
                </a-space>
            </template>
            <template #status="{record}">
                <a-tag :color="record.is_show ? '#00CC66':'#999' "
                       @click="updateGoodsCommentShow(record.id,record.is_show ? 0 :1)"
                >
                    {{ record.is_show ? '显示':'隐藏'}}
                </a-tag>
            </template>
            <template #action="{record}">
                <a-button @click="deleteGoodsComment(record.id)">删除</a-button>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination show-size-changer
                          :default-current="gcStatus.page"
                          :total="gcStatus.count"
                          @showSizeChange="(p,e)=>{getGoodsComment(gcStatus.page,e)}"
                          @change="(e)=>{getGoodsComment(e,gcStatus.limit)}"
            />
        </div>
    </a-modal>
</div>
</template>

<script>
import { useGoodsComment } from "@/models/goods";
import {ref} from "vue";
export default {
    name: "com-goods-comment",
    props:{
        goodsId:{
            type:Number
        }
    },
    setup(props){
        let show = ref(false)
        let { gcStatus,getGoodsComment ,deleteGoodsComment,updateGoodsCommentShow} = useGoodsComment(props.goodsId)
        function showComment(){
            show.value = true
            getGoodsComment(1,gcStatus.limit)
        }
        return{ gcStatus,showComment,show,deleteGoodsComment,updateGoodsCommentShow }
    }
}
</script>

<style scoped>

</style>
